import { createTheme, ThemeOptions } from "@mui/material/styles";

const defaultTheme = createTheme();
const { breakpoints } = defaultTheme;

const green = "#04af65";
const greenLight = "#e5edea";
const purple = "#8671ff";
const purpleLight = "#eae8f7";
const white = "#fff";

const options: ThemeOptions = {
  layout: {
    headerHeight: {
      mobile: "50px",
      desktop: "50px",
    },
    containerPaddingHorizontal: defaultTheme.spacing(2),
  },
  border: {
    light: "1px solid #F0F0F0",
    default: "1px solid #E5E5E7",
  },
  palette: {
    primary: {
      light: greenLight,
      main: green,
      contrastText: white,
    },
    secondary: {
      light: purpleLight,
      main: purple,
    },
  },
  typography: {
    bodySmall: {
      fontSize: "14px",
      [breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
    body1: {
      fontSize: "16px",
    },
    body2: {
      fontSize: "14px",
    },
    h1: {
      fontSize: "32px",
      [breakpoints.down("sm")]: {
        fontSize: "28px",
      },
      fontWeight: "bold",
    },
    h2: {
      fontSize: "27px",
      [breakpoints.down("sm")]: {},
      fontWeight: "bold",
    },
    h3: {
      fontSize: "23px",
      [breakpoints.down("sm")]: {
        fontSize: "20px",
      },
      fontWeight: "bold",
    },
    h4: {
      fontSize: "18px",
      fontWeight: "bold",
    },
    button: {
      textTransform: "none",
    },
    a: {
      textDecoration: "none",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        a: {
          color: green,
          textDecoration: "none",
        },
      },
    },
  },
};

export default createTheme(options);
