import { styled } from "@mui/material/styles";
import Markdown from "markdown-to-jsx";

const StyledMarkdown = styled(Markdown)(({ theme }) => ({
  "@media screen": {
    h1: {
      lineHeight: 1.1,
      fontSize: "40px",
      ":before": {
        content: `"❦"`,
        marginRight: theme.spacing(2),
      },
    },
    h2: {
      fontSize: "32px",
      ":before": {
        content: `"▎"`,
        color: theme.palette.primary.main,
      },
    },
    p: {
      fontSize: "20px",
      fontWeight: 300,
      lineHeight: "32px",
      letterSpacing: "-0.003em",
      wordBreak: "break-word",
      textAlign: "justify",
      a: {
        color: theme.palette.primary.main,
        fontWeight: 500,
      },
      strong: {
        background: theme.palette.primary.light,
        fontWeight: 500,
        paddingRight: 6,
        paddingLeft: 6,
        paddingTop: 2,
        paddingBottom: 2,
        borderRadius: 3,
      },
    },
    img: {
      float: "left",
      width: "50%",
      borderRadius: 10,
      marginRight: 23,
      filter: "grayscale(1)",
    },
    blockquote: {
      backgroundColor: theme.palette.primary.light,
      padding: "18px 23px",
      lineHeight: "12px",
      borderRadius: 12,
      margin: 0,
      ":before": {
        color: theme.palette.primary.main,
        content: `"❝"`,
        fontSize: "2em",
        lineHeight: "0.1em",
        marginRight: 10,
        verticalAlign: `-0.2em`,
      },
      p: {
        display: "inline",
        fontStyle: "italic",
        backgroundColor: theme.palette.primary.light,
      },
    },
  },
  "@media print": {
    borderTop: `12px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.grey[100],
    borderLeft: `12px solid ${theme.palette.secondary.main}`,
    borderRight: `10cm solid ${theme.palette.grey[200]}`,
    paddingBottom: "2cm",
    img: {
      float: "left",
      width: "50%",
      borderRadius: 10,
      marginRight: 23,
      filter: "grayscale(1)",
    },
    h1: {
      lineHeight: 1.1,
      fontSize: "52pt",
      ":before": {
        content: `"❦"`,
        marginRight: theme.spacing(2),
      },
      paddingLeft: "2cm",
      paddingRight: "2cm",
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.main,
      paddingTop: ".5cm",
      paddingBottom: "1cm",
      float: "none",
      pageBreakAfter: "avoid",
      ":not(:first-child)": {
        pageBreakBefore: "always",
      },
      ":first-child": {
        marginTop: "1.8cm",
        marginRight: "1.8cm",
        marginLeft: "1.8cm",
        padding: "1cm",
        fontSize: 50,
        height: "15cm",
        borderBottom: "13px solid",
        borderColor: theme.palette.secondary.main,
      },
    },
    h2: {
      pageBreakBefore: "always",
      fontSize: "26pt",
      lineHeight: "29pt",
      paddingTop: "1cm",
      ":before": {
        content: `"▎"`,
        color: theme.palette.secondary.main,
      },
    },
    h3: {
      paddingTop: "1cm",
      fontSize: "23pt",
      lineHeight: "25pt",
      pageBreakAfter: "avoid",
    },
    p: {
      fontSize: "16pt",
      lineHeight: "23pt",
      letterSpacing: "-0.003em",
      wordBreak: "break-word",
      textAlign: "justify",
      fontWeight: 300,
      a: {
        color: theme.palette.secondary.main,
        fontWeight: 500,
      },
      strong: {
        background: theme.palette.secondary.light,
        fontWeight: 500,
        paddingRight: 6,
        paddingLeft: 6,
        paddingTop: 2,
        paddingBottom: 2,
        borderRadius: 3,
      },
      paddingLeft: "2cm",
      paddingRight: "2cm",
      paddingTop: "1cm", // We add this because otherwise p are in the edge of the page
      marginBottom: "-1cm", // but in return we have to add this to counter the effect
      pageBreakInside: "avoid",
    },
    "& h2, h3, h4, h5": {
      pageBreakInside: "avoid",
      paddingLeft: "2cm",
      paddingRight: "2cm",
      marginBottom: "-1cm", // likewise, we have to add this to counter the effect of the padding in p
    },
    blockquote: {
      marginTop: "1.3cm", // also to counter the effect of the padding in p
      paddingLeft: "1cm",
      paddingRight: "1cm",
      p: {
        display: "flex",
        alignItems: "center",
        pageBreakInside: "avoid",
        backgroundColor: theme.palette.secondary.light,
        borderRadius: ".3cm",
        padding: ".5cm 1cm",
        ":before": {
          color: theme.palette.secondary.main,
          content: `"❝"`,
          fontSize: "40pt",
          marginRight: "1cm",
        },
      },
    },
  },
  "@page": {
    size: "A4 landscape",
  },
}));

export default StyledMarkdown;
