import Strings from "config/config.json";

export default function geterateUtm(websiteUrl) {
  const websiteUrlWithQuestionMark = websiteUrl.includes("?")
    ? websiteUrl + "&"
    : websiteUrl + "?";
  const landingId = Strings.code.replaceAll(" ", "-");
  const contentTitle = Strings.title.replaceAll(" ", "-");
  const campaignSource = "utm_source=" + landingId;
  const campaignMedium = "&utm_medium=" + landingId;
  const campaignName = "&utm_campaign=" + contentTitle;
  const utm =
    websiteUrlWithQuestionMark + campaignSource + campaignMedium + campaignName;
  return utm;
}
