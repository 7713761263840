import React from "react";
import { Typography, Box } from "@mui/material";
import contentMockup from "images/contentMockup.jpg";
import Strings from "config/config.json";
import useHeight from "theme/layout";

function ContentView() {
  const height = useHeight;
  const strings = Strings;
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      sx={{ height: { xs: "100%", md: `calc(100vh - ${height.footer}px)` } }}
    >
      <Box mt={4} px={2}>
        <Typography
          align="center"
          variant="overline"
          display="block"
          color="secondary"
          lineHeight={1}
          fontSize={18}
          fontWeight={700}
        >
          {strings.defaultText.overline}
        </Typography>
        <Typography
          align="center"
          variant="h1"
          fontSize={40}
          fontWeight={500}
          lineHeight={1}
          paragraph
        >
          {strings.title}
        </Typography>
        <Typography
          align="center"
          variant="subtitle"
          display="block"
          fontSize={18}
          paragraph
          sx={{ maxWidth: { xs: "100%", md: "90%" } }}
        >
          {strings.subtitle}
        </Typography>
      </Box>
      <img width="100%" src={contentMockup} alt={strings.title}></img>
    </Box>
  );
}

export default ContentView;
