import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Button,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import usePrint from "utils/usePrint";
import LegitHealthIcon from "images/LegitHealthIcon";
import doctorViewingCases from "images/doctorViewingCases.svg";
import utmGenerator from "utils/utmGenerator";
import Strings from "config/config.json";
import TagManager from "react-gtm-module";
import markdownContent from "./markdownContent.md";
import StyledMarkdown from "./components/StyledMarkdown";

function ContentBodyView({ urlSafeTitle }) {
  const strings = Strings;

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        pagePath: `read-content/${urlSafeTitle}`,
        pageTitle: strings.title,
      },
    });
  }, [strings.title, urlSafeTitle]);

  const { componentRef, handlePrint } = usePrint();
  const theme = useTheme();

  let [content, setContent] = useState({ md: "" });
  useEffect(() => {
    fetch(markdownContent)
      .then((result) => result.text())
      .then((md) => {
        setContent({ md });
      });
  }, []);

  return (
    <>
      <Container
        disableGutters={useMediaQuery(theme.breakpoints.up("sm"))}
        maxWidth="md"
        sx={{ mt: 4 }}
      >
        <Button
          variant="outlined"
          size="large"
          color="primary"
          onClick={handlePrint}
          startIcon={<PictureAsPdfIcon />}
        >
          {strings.defaultText.downloadPdf}
        </Button>
      </Container>
      <Box mb={20}>
        <Container
          disableGutters={useMediaQuery(theme.breakpoints.up("sm"))}
          maxWidth="md"
          ref={componentRef}
        >
          <StyledMarkdown children={content.md} />

          <footer>
            <Box
              p={4}
              pt="9.5cm"
              elevation={0}
              sx={{
                display: "none",
                borderColor: "secondary.main",
                "@media print": {
                  top: "auto",
                  bottom: 0,
                  right: "1cm",
                  height: "21cm",
                  width: "8cm",
                  justifyContent: "center",
                  backgroundColor: "#f5f5f5",
                  display: "inherit",
                  position: "fixed",
                },
              }}
            >
              <Box display="flex" alignItems="center" mb={2}>
                <LegitHealthIcon color="primary" sx={{ mr: 1 }} />
                <Typography
                  sx={{
                    fontFamily: "Roboto Condensed",
                    letterSpacing: ".23em",
                    backgroundBlendMode: "multiply",
                  }}
                  variant="h3"
                  color="primary"
                >
                  LEGIT.HEALTH
                </Typography>
              </Box>
              <Typography variant="h2" paragraph color="textSecondary">
                {strings.defaultText.companyTagline}
              </Typography>
              <img width="100%" alt="legit" src={doctorViewingCases} />
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                href={utmGenerator(strings.defaultCallToActionUrl)}
              >
                Más información
              </Button>
            </Box>
          </footer>
        </Container>
        <Container
          disableGutters={useMediaQuery(theme.breakpoints.up("sm"))}
          maxWidth="md"
          sx={{ mt: 4 }}
        >
          <Button
            variant="outlined"
            size="large"
            color="primary"
            onClick={handlePrint}
            startIcon={<PictureAsPdfIcon />}
          >
            {strings.defaultText.downloadPdf}
          </Button>
        </Container>
      </Box>
    </>
  );
}

export default ContentBodyView;
