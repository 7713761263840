import { useRef, MutableRefObject } from "react";
import { useReactToPrint } from "react-to-print";

type UsePrintArguments = {
  pageStyle: string;
};

type UsePrintReturn = {
  componentRef: MutableRefObject<null | HTMLDivElement>;
  handlePrint: (() => void) | undefined;
};

const defaultArguments = {
  pageStyle:
    "@page { size: A4 landscape,width:100%,height: 100% } @media print { body { -webkit-print-color-adjust: exact;} }",
};

export default function usePrint(
  props: UsePrintArguments = defaultArguments
): UsePrintReturn {
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: props.pageStyle,
  });

  return { componentRef, handlePrint };
}
