import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import Footer from "./components/Footer";
import Gate from "./components/Gate";
import Content from "./components/Content";
import theme from "./theme/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Strings from "config/config.json";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-PLFK35M",
};
TagManager.initialize(tagManagerArgs);

function App() {
  const urlSafeTitle = encodeURIComponent(
    Strings.title
      .replaceAll(" ", "-")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
  );

  useEffect(() => {
    document.title = `${Strings.title}. ${Strings.subtitle}`;
  }, []);

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Outlet />
        <Routes>
          <Route
            index
            path={`access-content/${urlSafeTitle}`}
            element={<Gate urlSafeTitle={urlSafeTitle} />}
          />
          <Route
            path={`read-content/${urlSafeTitle}`}
            element={<Content urlSafeTitle={urlSafeTitle} />}
          />
          <Route
            path="*"
            element={<Navigate replace to={`access-content/${urlSafeTitle}`} />}
          />
          <Route
            path="read-content/*"
            element={<Navigate replace to={`${urlSafeTitle}`} />}
          />
        </Routes>
        <Footer />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
